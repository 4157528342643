/* General styles for the entire application */
body {
  font-family: Work Sans, sans-serif;
  background-color: #d6f9f6;
  margin: 0;
  padding: 20px;
}

h2 {
  margin:0.5rem;
}

/* Styles for navigation links */
nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px 0;
  display: flex;
  gap: 10px;
}

nav ul li {
    display: inline;
    color: undefined;
}

nav ul li a {
    text-decoration: none;
    color: #5b809b;
    font-weight: bold;
}

nav ul li a:hover {
  text-decoration: underline;
}

/* Filters styles */
.filters {
  margin: 0.5rem;
  display: flex;
  gap: 15px;
}

.filters label {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Compact table styles */
table.compact-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
}

table.compact-table th, table.compact-table td {
  padding: 4px;
  text-align: center;
  border: 1px solid #ddd;
  white-space: nowrap;
}

table.compact-table th {
    background-color: #5b809b;
    color: white;
    cursor: pointer; 
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

table.compact-table td {
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Color coding for status cells */
td.booked {
  background-color: #b6d7a8;
}

td.unavailable {
    background-color: #ffbe9a;
}

td.available {
  background-color: white;
}

/* Styles for the refresh button */
button {
    padding: 5px 10px;
    background-color: #5b809b;
    color: white;
    border: none;
    cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
    background-color: #46677F;
}

/* Settings page styles */
input[type="text"],
input[type="number"],
select {
  padding: 5px;
  margin-right: 10px;
  font-size: 12px;
}

select {
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 5px 0;
  font-size: 12px;
}
/* Statistics bubble styles */
.statistics {
  position: static; 
  background-color: #f8f9fa;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  width: fit-content;
  z-index: 10;
  margin:0.5rem;
  text-align: center;
}

.statistics h4 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: bold;
}

.statistics ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.statistics li {
  margin-bottom: 5px;
}

.statisticsheadlines {
  display: inline-block;
  margin: 0.5rem;
  text-align: center;
}

.statistics h4{
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0px;
}

.statistics p{
  font-size: 1.5rem;
  font-weight: 900;
  margin: 0.5rem;
}