.settings-page {
  margin: 20px;
}

.listings-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.listings-table th, .listings-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.listings-table th {
  background-color: #007bff;
  color: white;
}

.listings-table input {
  width: 90%;
  padding: 5px;
  box-sizing: border-box;
}

.save-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.save-button:hover {
  background-color: #0056b3;
}